<template lang="pug">
  div
    v-file-input(
      ref="fileinput"
      :value="typeof value === 'string' ? null : value"
      :label="label"
      :errorMessages="errorMessages"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="changeHandler"
      @click:clear="this.cancelCrop"
    )
      template(#prepend-outer)
        img(:src="imageSrc")

    v-dialog(
      v-model="isOpenModal"
      :width="width"
      style="overlay-x: auto"
    )
      v-card(:width="width")
        v-card-title Обрезка изображения
        v-card-text
          Spinner(v-if="$root.isLoadingGlobal")
          Cropper(
            ref="cropper"
            v-if="this.value && typeof this.value !== 'string'"
            :src="cropperSrc"
            minWidth="30"
            @ready="readyHandler"
          )
          v-col.d-flex.justify-center
            v-btn.text--disabled.mr-3(
              outlined
              @click="cancelCrop"
            ) Отменить
            v-btn(
              :disabled="disabled"
              depressed
              color="primary"
              @click="cropImage"
            ) Подтвердить

</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import Spinner from '@/components/Spinner.vue';

import NoPhotoImg from '@/assets/images/no-photo.png';

export default {
  components: {
    Cropper,
    Spinner,
  },
  props: {
    value: {
      type: [File, String],
      default: () => '',
    },
    label: {
      type: String,
      default: 'Выберите изображение',
    },
    placeholder: {
      type: String,
      default: 'Выберите изображение',
    },
    fileCropperButton: {
      type: String,
      default: 'Сохранить изменения',
    },
    width: {
      type: String,
      default: '450px',
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },

    disabled: Boolean,
  },
  data() {
    return {
      cropperReady: false,
      isOpenModal: false,
      isLoading: false,
      croppedFile: false,
      timeoutId: false,
    };
  },

  computed: {
    imageSrc() {
      if (typeof this.value === 'string') {
        return this.value;
      } else if (this.value instanceof File) {
        return URL.createObjectURL(this.value);
      } else return NoPhotoImg;
    },
    cropperSrc() {
      return URL.createObjectURL(this.value);
    },
  },

  methods: {
    cropImage() {
      this.isLoading = true;
      const { canvas } = this.$refs.cropper.getResult();

      canvas.toBlob(blob => {
        this.$emit('input', new File([blob], this.value.name));
        this.isOpenModal = false;
      });
    },
    changeHandler(file) {
      if (!file) return;
      if (file !== '' && !file.type.startsWith('image/'))
        return this.errorCrop('Поддерживаются только изображения');
      this.$emit('input', file);
      this.isOpenModal = true;
      this.isLoading = true;

      this.timeoutId = setTimeout(() => {
        if (this.isLoading === true)
          this.errorCrop(
            'Не удалось обработать изображение. Попробуйте изображение с большим размером сторон.',
          );
      }, 5000);
    },
    cancelCrop() {
      this.isOpenModal = false;
      this.isLoading = false;
      this.$nextTick(() => this.$emit('input', null));
    },
    errorCrop(description) {
      this.$emit('error', description);
      this.$nextTick(() => this.$refs.fileinput.clearableCallback());
      this.cancelCrop();
    },
    readyHandler() {
      clearTimeout(this.timeoutId);
      this.isLoading = false;
    },
  },
};
</script>
