<template lang="pug">
  v-card(max-width="700")
      v-card-title.d-flex.justify-space-between
        span {{employee.id ? "Редактировать работника" : "Добавить работника"}}
        v-btn(
          icon
          @click="onCancel"
        )
          v-icon fa-times
      v-card-text
        v-form(:disabled="disabled || loading")
          v-text-field(
            required
            v-model="employee.surname"
            label="Фамилия"
            :error-messages="getValidationErrors('employee.surname')"
            :disabled="disabled"
          )
          v-text-field(
            required
            v-model="employee.name"
            label="Имя"
            :error-messages="getValidationErrors('employee.name')"
            :disabled="disabled"
          )
          v-text-field(
            v-model="employee.patronymic"
            label="Отчество"
            :error-messages="getValidationErrors('employee.patronymic')"
            :disabled="disabled"
          )
          v-radio-group(
              v-model="employee.gender"
              row
              label="Пол"
              :error-messages="getValidationErrors('employee.gender')"
            )
              v-radio(
                value="MALE"
                label="Мужчина"
              )
              v-radio(
                value="FEMALE"
                label="Женщина"
              )
          ImageCropper(
            v-if="!employee.id"
            v-model="photo"
            label="Фото работника"
            :error-messages="getValidationErrors('photo')"
            :disabled="disabled"
            @error="$emit('error', description)"
          )

          DatePicker(
            v-model="employee.dateOfBirth"
            label="Дата рождения"
            :error-messages="getValidationErrors('employee.dateOfBirth')"
          )

          v-text-field(
            v-model="employee.phone"
            label="Телефон"
            v-mask="'+7 (###) ###-##-##'"
            placeholder="+7 (___) ___-__-__"
            :error-messages="getValidationErrors('employee.phone')"
            :disabled="disabled"
          )
          OrganizationPicker(
            :canSelectAll="false"
            v-model="employee.organization.id"
            :hideDetails="false"
            :items="organizations"
            :error-messages="getValidationErrors('employee.organization.id')"
            :disabled="!!employee.id"
          )
          v-text-field(
            required
            v-model="employee.personellNumber"
            label="Табельный номер"
            hintColor="orange"
            :error-messages="getValidationErrors('employee.personellNumber')"
          )
          v-text-field(
            v-model="employee.license"
            label="Водительское удостоверение"
            :error-messages="getValidationErrors('employee.license')"
            :disabled="disabled"
            counter="10"
          )

          v-checkbox(
            v-model="employee.status"
            label="Активный"
            :disabled="disabled"
            detail="Статус водителя: "
          )

          v-row(align-end)
            v-col(cols="12" sm="6")
              v-btn(
                @click="onCancel"
                outlined
                :disabled="disabled || loading"
                block
              ) Отмена
            v-col(cols="12" sm="6")
              v-btn(
                @click="onSubmit"
                block
                depressed
                color="primary"
                :loading="loading || !validationsIsLoaded"
              ) {{employee.id ? "Сохранить изменения" : "Добавить работника"}}
</template>

<script>
import validationMixin from '@/utils/validation';
import {
  cyrillicName,
  date,
  minAge,
  maxAge,
  driverLicense,
  phone,
  fileImageOrPath,
} from '@/utils/validators';
import { required, maxLength } from 'vuelidate/lib/validators';

import DatePicker from '@/components/controls/DatePicker.vue';
import ImageCropper from '@/components/controls/ImageCropper.vue';
import OrganizationPicker from '@/components/controls/OrganizationPicker.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: { DatePicker, ImageCropper, OrganizationPicker },
  mixins: [validationMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      employee: {
        averages: {},
        gender: 'MALE',
        organization: { id: null, name: '' },
        status: true,
      },
      // initially cropper wants to see photo as string if it's empty
      photo: '',
      validations: {},
      validationsIsLoaded: false,
    };
  },

  validations: {
    employee: {
      name: { required, cyrillicName, maxSymbolsLength: maxLength(64) },
      surname: { required, cyrillicName, maxSymbolsLength: maxLength(64) },
      patronymic: { cyrillicName, maxSymbolsLength: maxLength(64) },
      dateOfBirth: {
        required,
        date,
        minAge: minAge(18),
        maxAge: maxAge(100),
      },
      license: { driverLicense },
      gender: { required },
      phone: { phone },
      organization: {
        id: { required },
      },
      personellNumber: {
        rule(value) {
          const pattern = new RegExp(this.validations.personellNumber?.pattern);
          return pattern.test(value);
        },
      },
    },
    photo: {
      fileImageOrPath(value) {
        return !this.photo ? true : fileImageOrPath(value);
      },
    },
  },

  computed: {
    ...mapGetters(['organizations']),
    ...mapGetters('EMPLOYEE', ['item']),

    validationMessages() {
      return {
        'employee.personellNumber.rule': this.validations.personellNumber
          ?.patternExplanation,
      };
    },
  },

  watch: {
    'employee.organization.id': {
      immediate: true,
      async handler(value) {
        this.validationsIsLoaded = false;
        this.validations = {};
        this.validations = await this.fetchValidationRules(value);
        this.validationsIsLoaded = true;
      },
    },
  },

  mounted() {
    // deep copy of an employee object
    this.item.id && (this.employee = JSON.parse(JSON.stringify(this.item)));
  },

  methods: {
    ...mapActions('EMPLOYEE', ['create', 'update', 'fetchValidationRules']),

    onCancel() {
      this.$emit('cancel');
    },

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.setServerValidationErrors([]);

      try {
        const result = this.employee.id
          ? await this.update(this.employee)
          : await this.create({ employee: this.employee, photo: this.photo });

        const action = this.employee.id ? 'update' : 'create';

        this.$emit('success', result, action);
      } catch (error) {
        if (error?.response?.status === 400) {
          this.setServerValidationErrors(error.response.data.inner, 'employee');
        } else {
          console.error(error);
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Ошибка. Попробуйте позже.',
          });
        }
      }
    },
  },
};
</script>
