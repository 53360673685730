<template lang="pug">
v-menu(
  ref="menu"
  :close-on-content-click="false"
  transition="scale-transition"
  offset-y
  min-width="290px"
)
  template(#activator="{ on, attrs }")
    v-text-field(
      :value="value"
      :type="typeTextField"
      :append-icon="typeTextField==='text' ? 'fa-calendar' : null"
      :label="label"
      :readonly="readonly"
      required
      placeholder="дд-мм-гггг"
      v-bind="attrs"
      :error-messages="errorMessages"
      :disabled="$root.isDisabledGlobal"
      v-on="on"
      @input="updateDate"
    )
  v-date-picker(
    @input="updateDate"
    :type="typeDatePicker"
    no-title
    scrollable
  )
</template>

<script>
// FIXME: component should not have direct connection with root component
export default {
  props: {
    typeTextField: { type: String, default: 'date' },
    typeDatePicker: { type: String, default: 'date' },
    readonly: { type: Boolean, default: false },
    value: { type: String, default: '' },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    label: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      date: this.value,
    };
  },

  methods: {
    updateDate(value) {
      this.$refs.menu.save(value);
      this.$emit('input', value);
    },
  },
};
</script>
